import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';
import TypeMate from 'typemate';

import 'normalize.css/normalize.css';
import 'flexboxgrid/css/flexboxgrid.min.css';
import 'aos/dist/aos.css';

import './sass/index.scss';
import './sass/_helpers.scss';
import './sass/_common.scss';
import './sass/_responsive.scss';
import './sass/_navigation.scss';
import './sass/_sections.scss';

const addStickyNavigation = () => {
  const navigation = document.querySelector('.navigation');
  const fixedClassName = 'navigation--fixed';
  if (scrollY > 43) {
    navigation.classList.add(fixedClassName);
  } else {
    navigation.classList.remove(fixedClassName);
  }
};

const toggleMenu = () => {
  const navigation = document.querySelector('.navigation');
  if (navigation.classList.contains('navigation--expanded')) {
    navigation.classList.remove('navigation--expanded');
  } else {
    navigation.classList.add('navigation--expanded');
  }
};

const setNavigationHeight = () => {
  const navigation = document.querySelector('.navigation__container');
  if (window.innerWidth > 767) {
    navigation.style.height = 'auto';
  } else {
    navigation.style.height = null;
  }
};

const setFullHeightt = () => {
  const fullHeightSections = document.querySelectorAll('.js-full-height');
  fullHeightSections.forEach(item => {
    if (window.innerWidth > 767) {
      item.style.height = `${document.documentElement.clientHeight}px`
    } else {
      item.style.height = null;
    };
  });
};

const setMobileFullHeight = () => {
  const mobileFullHeightSection = document.querySelectorAll('.js-mobile-full-height');
    mobileFullHeightSection.forEach(item => {
      if (window.innerWidth < 992 && window.innerWidth > 340) {
        item.style.height = `${document.documentElement.clientHeight}px`;
      } else {
        item.style.height = null;
      }
    });
};

document.addEventListener("DOMContentLoaded", () => {
  new SmoothScroll('a[href*="#"]', { easing: 'linear', speed: 300, offset: 64, speedAsDuration: true });
  const hamburgerButton = document.querySelector('.navigation__close');
  const navigationLinks = document.querySelectorAll('.navigation__link');
  const typeMateInstance = new TypeMate(document, { selector: 'h1, h2, p' });
  navigationLinks.forEach(link => {
    link.addEventListener('click', () => {
      document.querySelector('.navigation').classList.remove('navigation--expanded');
    });
  });
  AOS.init();
  setNavigationHeight();
  setMobileFullHeight();

  hamburgerButton.addEventListener('click', toggleMenu);

  addStickyNavigation();
  window.addEventListener('scroll', addStickyNavigation);
  window.addEventListener('resize', () => {
    setNavigationHeight();
    setMobileFullHeight();
  });
  typeMateInstance.apply();
});
